<template>
  <b-container fluid>
      <iq-card body-class=" profile-page p-0">
          <template v-slot:headerTitle >
           <h4 class="card-title">Administrators</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="secondary" size="lg" id="show-btn" @click="showModal">Add New Administrator</b-button>
             <b-modal ref="my-modal" hide-footer title="Manage the information of the administrators">
                  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
                    <form @submit.prevent="handleSubmit(onSubmit)">
                      <b-row align-v="center">
                        <b-form-group class="col-md-6 col-sm-12" label="Names" label-for="names">
                            <ValidationProvider name="Names" rules="required" v-slot="{ errors }">
                            <b-form-input v-model="user.name" type="text" placeholder="Names" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                                <span>{{ errors[0] }}</span>
                            </div>
                            </ValidationProvider>
                        </b-form-group>
                        <b-form-group class="col-md-6 col-sm-12" label="Last Name" label-for="lname">
                            <ValidationProvider name="Last name" rules="required" v-slot="{ errors }">
                            <b-form-input v-model="user.last" type="text" placeholder="Last Names" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                                <span>{{ errors[0] }}</span>
                            </div>
                            </ValidationProvider>
                        </b-form-group>
                        <b-form-group class="col-md-12" label="Email" label-for="email">
                            <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                            <b-form-input v-model="user.email" type="email" placeholder="Contact Email" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                                <span>{{ errors[0] }}</span>
                            </div>
                            </ValidationProvider>
                        </b-form-group>
                        <b-form-group class="col-md-12" label="Company" label-for="age">
                            <b-form-input v-model="user.company" type="text" placeholder="My company name"></b-form-input>
                        </b-form-group>
                        <b-form-group class="col-md-12" label="Phone" label-for="phone">
                            <ValidationProvider name="Phones" rules="required" v-slot="{ errors }">
                            <b-form-input v-model="user.phone" type="text" placeholder="Number phone" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                                <span>{{ errors[0] }}</span>
                            </div>
                            </ValidationProvider>
                        </b-form-group>
                        <b-form-group class="col-md-12" label="Web Site" label-for="website">
                            <b-form-input v-model="user.website" type="text" placeholder="Link web site"></b-form-input>
                        </b-form-group>
                      </b-row>
                    <b-button class="mt-3  mr-2 px-5" variant="outline-danger"  @click="hideModal">Close </b-button>
                    <b-button class="mt-3 px-5" variant="primary"  type="submit">Submit</b-button>
                    </form>
                  </ValidationObserver>
            </b-modal>
          </template>
          <template v-slot:body>
             <b-col cols="12" >
                 <div>
                  <b-tabs v-model="tabIndex" class="nav-fill" >
                    <!-- CONTENEDOR DE INFORMACION -->
                    <div v-if="tabIndex == 0">
                      <div>
                        <p class="h3">LIST <small class="text-muted h5">ADMINISTRATORS</small>  ACTIVE </p>
                      </div>
                    </div>
                    <div v-if="tabIndex == 1">
                      <div>
                        <p class="h3">LIST <small class="text-muted h5">ADMINISTRATORS</small>  INACTIVE </p>
                      </div>
                    </div>
                    <!-- TAB ADMINISTRADORES ACTIVOS -->
                    <b-tab  title-item-class="mytab"  active>
                      <template #title >
                        <div>
                         <i class="ri-user-settings-line h1"></i>
                        <p class="text-center"><strong>ACTIVES</strong></p>
                        </div>
                      </template>
                      <b-row>
                        <b-col sm="12">
                          <!-- CONTENIDO TAB 1 -->
                          <div class="app-container">
                            <div v-if="data.length > 0">
                              <iq-card>
                                <template v-slot:body>
                                <ag-grid-vue
                                  id="ag-grid"
                                  class="ag-theme-material border height-500"
                                  :columnDefs="tableHeader"
                                  :rowData="data"
                                  :floatingFilter="true"
                                  :modules="modules"
                                  rowSelection="multiple"
                                  :resizable="true"
                                  :pagination=true
                                  :paginationPageSize="paginationPageSize"
                                  style="width: 100%; height: 80vh"
                                ></ag-grid-vue>
                                </template>
                              </iq-card>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </b-tab>
                    <!-- TAB ADMINISTRADORES INACTIVOS -->
                    <b-tab >
                      <template #title>
                        <i class="ri-admin-line h1"></i>
                        <p class="text-center"><strong>INACTIVE</strong></p>
                      </template>
                      <b-row>
                        <b-col sm="12">
                          <!-- CONTENIDO TAB 1 -->
                          <div class="app-container">
                            <div v-if="data.length > 0">
                              <iq-card>
                                <template v-slot:body>
                                <ag-grid-vue
                                  id="ag-grid"
                                  class="ag-theme-material border height-500"
                                  :columnDefs="tableHeader"
                                  :rowData="dataInactive"
                                  :floatingFilter="true"
                                  :modules="modules"
                                  rowSelection="multiple"
                                  :resizable="true"
                                  :pagination=true
                                  :paginationPageSize="paginationPageSize"
                                  style="width: 100%; height: 80vh"
                                ></ag-grid-vue>
                                </template>
                              </iq-card>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </b-tab>
                  </b-tabs>
                </div>
              </b-col>

          </template>
        </iq-card>
  </b-container>
</template>
<script>
import 'ag-grid-community/dist/styles/ag-grid.min.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { AgGridVue } from 'ag-grid-vue'
import { AllCommunityModules } from '@ag-grid-enterprise/all-modules'
import ActionUser from '@/views/SuperAdmin/Components/ActionSuperAdmin'

import { core } from '../../config/pluginInit'
import { Arabic } from 'flatpickr/dist/l10n/ar'
import { english } from 'flatpickr/dist/l10n/default'
import { mapGetters } from 'vuex'
import { db, firebaseApp2 } from '../../config/firebase'
export default {
  name: 'DashboardAdmin',
  mounted () {
    core.index()
  },
  watch: {
    rtl: function (value) {
      this.options.rtl = this.rtl
    }
  },
  computed: {
    ...mapGetters({
      rtl: 'Setting/rtlModeState',
      lang: 'Setting/langState'
    })
  },
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    ActionUser
  },
  data () {
    return {
      data: [],
      dataInactive: [],
      user: {
        name: '',
        last: '',
        company: '',
        phone: '',
        website: '',
        email: '',
        uid: '',
        status: true
      },
      userDefaultItem: {
        name: '',
        last: '',
        company: '',
        phone: '',
        website: '',
        email: '',
        uid: '',
        status: true
      },
      config: {
        dateFormat: 'Y-m-d',
        inline: true,
        locale: english
      },
      arConfig: {
        dateFormat: 'Y-m-d',
        inline: true,
        locale: Arabic
      },
      items: [],
      properties: null,
      identifiers: null,
      paginationPageSize: null,
      modules: AllCommunityModules,
      tabIndex: 0
    }
  },
  firestore () {
    this.getAdmins()
  },
  beforeMount () {
    this.tableHeader = [
      { headerName: 'Name', field: 'name', sortable: true, filter: true },
      { headerName: 'Email', field: 'email', sortable: true, filter: true },
      { headerName: 'Company name', field: 'company_name', sortable: true, filter: true },
      { headerName: 'Phone', field: 'phone', sortable: true, filter: true },
      { headerName: 'Website', field: 'website', sortable: true, filter: true },
      { headerName: 'Actions', cellRendererFramework: ActionUser }
    ]
    this.paginationPageSize = 10
  },
  methods: {
    async getAdmins () {
      await db.collection('administrators')
        .where('status', '==', true)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const object = doc.data()
            object.idDoc = doc.id
            this.data.push(object)
          })
        })
      await db.collection('administrators')
        .where('status', '==', false)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const object = doc.data()
            object.idDoc = doc.id
            this.dataInactive.push(object)
          })
        })
    },
    showModal () {
      this.$refs['my-modal'].show()
    },
    hideModal () {
      this.user = Object.assign({}, this.userDefaultItem)
      this.$refs.observer.reset()
      this.$refs['my-modal'].hide()
    },
    async onSubmit () {
      let isSuccess = false
      const password = this.user.email
      await firebaseApp2.auth().createUserWithEmailAndPassword(this.user.email, password)
        .then((userCredential) => {
          this.user.uid = userCredential.user.uid
          firebaseApp2.auth().signOut()
          isSuccess = true
        }).catch((err) => {
          if (err.code === 'auth/email-already-exists') {
            core.showSnackbar('error', 'These credentials are already registered.')
          } else {
            core.showSnackbar('error', err.message)
          }
        })
      if (isSuccess) {
        await db.collection('administrators').add({
          name: this.user.name + ' ' + this.user.last,
          email: this.user.email,
          company_name: this.user.company || '',
          website: this.user.website || '',
          profile_image: '/assets/images/user/user-11.png',
          phone: this.user.phone,
          uid: this.user.uid,
          status: this.user.status
        })
          .then(() => {
            this.data.push({
              name: this.user.name + ' ' + this.user.last,
              email: this.user.email,
              company_name: this.user.company || '',
              website: this.user.website || '',
              profile_image: '/assets/images/user/user-11.png',
              phone: this.user.phone,
              uid: this.user.uid,
              status: this.user.status
            })
            core.showSnackbar('success', 'This user has been created successfully.')
            this.hideModal()
          })
          .catch((err) => {
            core.showSnackbar('error', err.message)
          })
      }
    },
    generatePassword () {
      const length = 4
      const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      let retVal = ''
      for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n))
      }
      return retVal
    }
  }
}
</script>
<style>
#post-slider .slick-arrow{
  top: 25px;
}
#post-slider .slick-arrow.left {
    left: auto;
    right: 100px;
}
.slick-arrow.right {
    right: 65px;
    top: 0;
}
</style>
